import { ComputedRef, Ref, ref } from "vue";
import nameMatchingFileMethods from "@/composables/nameMatchingFileMethods";
import additionalFilesMainComposable from "@/composables/additionalFilesMainComposable";
import { AdditionalFileType } from "@/enums/AdditionalFileType";
import { UploadStatuses } from "@/enums/UploadStatuses";
import QuoteService from "@/services/QuoteService";
import AdditionalThreeDFile from "@/types/request-for-quote/AdditionalThreeDFile";
import AdditionalDrawingFile from "@/types/request-for-quote/AdditionalDrawingFile";
import RfqInterface from "@/types/request-for-quote/RfqInterface";
import RfqPartInterface from "@/types/request-for-quote/RfqPartInterface";
import FileForUploadInterface from "@/types/FileForUploadInterface";
import { FileEntityClass } from "@/types/FileEntity";
import { ModelEntityClass } from "@/types/ModelEntity";

export default (
  rfq: Ref<RfqInterface>,
  partList: Ref<RfqPartInterface[]>,
  setSaveTime: (updatedAt: string) => void,
  filesForUpload: Ref<FileForUploadInterface[]>,
  threeDFiles: Ref<File[]>,
  pdfFiles: Ref<File[]>
) => {
  const modelAdditionalList = ref<AdditionalThreeDFile[]>([]);
  const drawingAdditionalList = ref<AdditionalDrawingFile[]>([]);
  const additionalFiles = ref<(AdditionalThreeDFile | AdditionalDrawingFile)[]>(
    []
  );
  const additionalFilesLoadingIndexes = ref<Set<number>>(new Set());

  const { sortAdditionalFiles } = additionalFilesMainComposable(
    modelAdditionalList,
    drawingAdditionalList,
    additionalFiles,
    additionalFilesLoadingIndexes
  );

  const setAdditionalFiles = (
    unconnectedThreeDFiles?: ComputedRef<File[]>,
    unconnectedPdfFiles?: ComputedRef<File[]>
  ) => {
    if (!unconnectedThreeDFiles && !unconnectedPdfFiles) {
      return;
    }
    if (unconnectedThreeDFiles) {
      modelAdditionalList.value.push(
        ...getNewAdditionalThreeDFiles(unconnectedThreeDFiles)
      );
    }
    if (unconnectedPdfFiles) {
      drawingAdditionalList.value.push(
        ...getNewAdditionalPdfFiles(unconnectedPdfFiles)
      );
    }
    additionalFiles.value = [
      ...modelAdditionalList.value,
      ...drawingAdditionalList.value,
    ];
    additionalFiles.value.sort(sortAdditionalFiles);
  };

  const getNewAdditionalThreeDFiles = (
    unconnectedThreeDFiles: ComputedRef<File[]>
  ) => {
    const newFiles = unconnectedThreeDFiles.value.filter(
      (file) =>
        !modelAdditionalList.value.find(
          (item) => getAdditionalFileName(item) === file.name
        )
    );

    return newFiles.map((model): AdditionalThreeDFile => {
      return {
        id: "",
        fileId: new ModelEntityClass(),
        file: model,
        type: AdditionalFileType.Model,
        status: UploadStatuses.Empty,
      };
    });
  };

  const getNewAdditionalPdfFiles = (
    unconnectedPdfFiles: ComputedRef<File[]>
  ) => {
    const newFiles = unconnectedPdfFiles.value.filter(
      (file) =>
        !drawingAdditionalList.value.find(
          (item) => getAdditionalFileName(item) === file.name
        )
    );
    return newFiles.map((drawing): AdditionalDrawingFile => {
      return {
        id: "",
        fileId: new FileEntityClass(),
        file: drawing,
        type: AdditionalFileType.Drawing,
        status: UploadStatuses.Empty,
      };
    });
  };

  const onCreatePartByAdditionalFile = (payload: {
    item: AdditionalThreeDFile;
    additionalFileIndex: number;
  }) => {
    const { item, additionalFileIndex } = payload;
    additionalFilesLoadingIndexes.value.add(additionalFileIndex);
    const nameWithoutExtension = getFileNameWithoutExtension(
      getAdditionalFileName(item)
    );
    const matchingDrawing = drawingAdditionalList.value.find(
      (drawing) =>
        getFileNameWithoutExtension(getAdditionalFileName(drawing)) ===
        nameWithoutExtension
    );
    let matchingDrawingIndex = matchingDrawing
      ? additionalFiles.value.indexOf(matchingDrawing)
      : -1;
    const matchingDrawingId = matchingDrawing
      ? matchingDrawing.fileId.rfqUploadId || matchingDrawing.id || undefined
      : undefined;
    if (matchingDrawingId) {
      additionalFilesLoadingIndexes.value.add(matchingDrawingIndex);
    }

    QuoteService.createRfqPartByFile({
      rfqId: rfq.value.id,
      parts: [
        {
          customerPNRev: nameWithoutExtension,
          modelId: item.fileId.rfqUploadId || item.id,
          ...(matchingDrawingId && {
            drawingId: matchingDrawingId,
          }),
        },
      ],
    })
      .then((res) => {
        const data = res.data.data.items[0];
        const part = {
          id: data.id,
          name: nameWithoutExtension,
          model: { name: data.modelId.fileId.originalUploadId.filename },
          drawing: matchingDrawingId
            ? { name: data.drawingId.fileId.originalUploadId.filename }
            : undefined,
          customerPNRev: data.customerPNRev,
          status: 0,
          quantities: [],
          remarks: [],
          modelStatus: item.status,
          drawingStatus: matchingDrawingId
            ? matchingDrawing?.status || UploadStatuses.Empty
            : UploadStatuses.Empty,
          modelId: data.modelId || {
            id: item.fileId.id,
            fileId: new ModelEntityClass(),
          },
          drawingId: data.drawingId || {
            id: "",
            fileId: new FileEntityClass(),
          },
        };
        partList.value.push(part);
        removeFileFromCollections(item);
        additionalFiles.value.splice(additionalFileIndex, 1);
        if (matchingDrawing && matchingDrawingId) {
          removeFileFromCollections(matchingDrawing);
          matchingDrawingIndex = additionalFiles.value.indexOf(matchingDrawing);
          additionalFiles.value.splice(matchingDrawingIndex, 1);
        }
        setSaveTime(Date());
      })
      .finally(() => {
        additionalFilesLoadingIndexes.value.delete(additionalFileIndex);
        if (matchingDrawingId) {
          additionalFilesLoadingIndexes.value.delete(matchingDrawingIndex);
        }
      });
  };

  const onRemoveAdditionalFile = (payload: {
    item: AdditionalThreeDFile | AdditionalDrawingFile;
    additionalFileIndex: number;
  }) => {
    const { item, additionalFileIndex } = payload;
    additionalFilesLoadingIndexes.value.add(additionalFileIndex);
    if (!item.fileId) {
      removeFileFromCollections(item);
      additionalFiles.value.splice(additionalFileIndex, 1);
      filesForUpload.value = filesForUpload.value.filter(
        (el) => el.file.name !== getAdditionalFileName(item)
      );

      additionalFilesLoadingIndexes.value.delete(additionalFileIndex);
      return;
    }
    QuoteService.deleteFileFromRfq(item.fileId.rfqUploadId || item.id)
      .then(() => {
        removeFileFromCollections(item);
        additionalFiles.value.splice(additionalFileIndex, 1);
        setSaveTime(Date());
      })
      .finally(() => {
        additionalFilesLoadingIndexes.value.delete(additionalFileIndex);
      });
  };

  const removeFileFromCollections = (
    item: AdditionalThreeDFile | AdditionalDrawingFile
  ) => {
    const itemFileName = getAdditionalFileName(item);
    if (item.type === AdditionalFileType.Model) {
      threeDFiles.value = threeDFiles.value.filter(
        (file) => file.name.toLowerCase() !== itemFileName.toLowerCase()
      );
      modelAdditionalList.value = modelAdditionalList.value.filter(
        (model) =>
          getAdditionalFileName(model).toLowerCase() !==
          itemFileName.toLowerCase()
      );
    } else {
      pdfFiles.value = pdfFiles.value.filter(
        (file) => file.name.toLowerCase() !== itemFileName.toLowerCase()
      );
      drawingAdditionalList.value = drawingAdditionalList.value.filter(
        (drawing) => getAdditionalFileName(drawing) !== itemFileName
      );
    }
  };

  const onRetryAdditionalFileUpload = (
    index: number,
    setFilesToUpload: (allFilesForUpload: FileForUploadInterface[]) => void
  ) => {
    const item = additionalFiles.value[index];
    if (!item.file) {
      return;
    }
    item.status = UploadStatuses.Pending;
    const fileToUpload = {
      file: item.file,
      rfqId: rfq.value.id,
      uploadStatus: UploadStatuses.Pending,
    };
    setFilesToUpload([fileToUpload]);
  };

  const { getAdditionalFileName, getFileNameWithoutExtension } =
    nameMatchingFileMethods();

  return {
    modelAdditionalList,
    drawingAdditionalList,
    additionalFiles,
    additionalFilesLoadingIndexes,
    setAdditionalFiles,
    onCreatePartByAdditionalFile,
    onRemoveAdditionalFile,
    onRetryAdditionalFileUpload,
    sortAdditionalFiles,
  };
};
